import React from "react";
import "./ModuleInfo.css";

export default function ModuleInfo() {
  return (
    <div className="mod-container">
      <h1>Modules</h1>
      <p>
        Welcome to the Future of IBACON
        <div className="mod-link-container">
          <a id="mod-link1" href="#mod-screen2">
            Stage
          </a>
          <a id="mod-link2" href="#mod-screen3">
            {" "}
            Arts
          </a>
          <a id="mod-link3" href="#mod-screen4">
            {" "}
            Gaming
          </a>
          <a id="mod-link4" href="#mod-screen6">
            {" "}
            Auditorium{" "}
          </a>
          <a id="mod-link5" href="#mod-screen5">
            {" "}
            Volleyball{" "}
          </a>
        </div>
        <p></p>
      </p>
    </div>
  );
}
